import React, { forwardRef } from "react";
import "./ProductCard.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectUserEmail } from "../../redux/slice/authSlice";
import { useSelector } from "react-redux";
import useFetchHook from "../../hooks/useFetchHook";

const ProductCard = forwardRef(
  (
    { product,adminpage, editOrDelete, admin, withOpacity, isDragging, style, ...props },
    ref
  ) => {
    const { t } = useTranslation();
    const categories = useFetchHook("category");
    const userEmail = useSelector(selectUserEmail);
    const cat = categories?.find((element) => element.useremail === userEmail);

    const inlineStyles = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      minHeight: "fit-content",
      height: adminpage ? "590px" : "530px" ,
      borderRadius: "10px",
      cursor: admin ? isDragging ? "grabbing" : "grab" : "normal",
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };



    const AvailableLangs =
      product && product.languages
        ? product.languages.map((obj) => obj.lang)
        : [];

    const incVat =
      product && product.price && product.vat
        ? (parseFloat(product.price) * parseFloat(product.vat)) / 100 +
          parseFloat(product.price)
        : 0;

    return product ? (
      <div
        ref={ref}
        style={inlineStyles}
        {...props}
        className="all-products-item position-relative"
      >
        <div className="image-container">
          <img src={product.imageUrl} alt={product.name} className="product-image" />
        </div>
        <div className="product-details">
          <p
            className={
              product.type === `physical software`
                ? `product-type`
                : `product-type yellow`
            }
          >
            {product.type}
          </p>
          <h3 className="product-name">{product.name}</h3>
          {admin && (
            <>
              <div className="d-flex">
                <p className="product-price">
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    Excl.
                  </span>{" "}
                  {"VAT"} €{" "}
                  <span className="theprice">
                    {parseFloat(product.price).toFixed(2)}
                  </span>{" "}
                  (Price Per Unit)
                </p>
                <p className="product-price">
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    Incl.
                  </span>{" "}
                  {"VAT"} €{" "}
                  <span className="theprice">{incVat.toFixed(2)}</span> (Price
                  Per Unit)
                </p>
              </div>

              {product["price(B2B)"] && (
                <>
                  <p className="product-price">
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Excl.B2B price
                    </span>{" "}
                    {"VAT"} €{" "}
                    <span className="theprice">
                      {parseFloat(product["price(B2B)"]).toFixed(2)}
                    </span>{" "}
                    (Price Per Unit)
                  </p>
                  <p className="product-price">
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Incl.B2B price
                    </span>{" "}
                    {"VAT"} €{" "}
                    <span className="theprice">
                      {parseFloat(product.b2bpriceWVat).toFixed(2)}
                    </span>{" "}
                    (Price Per Unit)
                  </p>
                </>
              )}
            </>
          )}

          {(cat?.cat === "B2C" && !admin) || !userEmail ? (
            <>
              <p className="product-price">
                <span style={{ color: "blue", fontWeight: "bold" }}>Excl.</span>{" "}
                {"VAT"} €{" "}
                <span className="theprice">
                  {parseFloat(product.price).toFixed(2)}
                </span>{" "}
                (Price Per Unit)
              </p>
              <p className="product-price">
                <span style={{ color: "red", fontWeight: "bold" }}>Incl.</span>{" "}
                {"VAT"} € <span className="theprice">{incVat.toFixed(2)}</span>{" "}
                (Price Per Unit)
              </p>
            </>
          ) : cat?.cat === "B2B" && !admin ? (
            <>
              <p className="product-price">
                <span style={{ color: "red", fontWeight: "bold" }}>Excl.</span>{" "}
                {"VAT"} €{" "}
                <span className="theprice">
                  {parseFloat(product["price(B2B)"]).toFixed(2)}
                </span>{" "}
                (Price Per Unit)
              </p>
              <p className="product-price">
                <span style={{ color: "red", fontWeight: "bold" }}>Incl.</span>{" "}
                {"VAT"} €{" "}
                <span className="theprice">
                  {parseFloat(product.b2bpriceWVat).toFixed(2)}
                </span>{" "}
                (Price Per Unit)
              </p>
            </>
          ) : (
            ""
          )}
          {product.PN && <p>PN: {product.PN}</p>}
          {AvailableLangs.length > 0 && (
            <p className="font-weight-bold">
              Available Languages:{" "}
              <span style={{ fontSize: "15px", color: "#666" }}>
                {AvailableLangs.join(", ")}
              </span>
            </p>
          )}
          {true &&
          <div className="d-flex items-center w-100 justify-content-between">
{product?.stockAmount ? <p>{`Stock Number:   ${product?.stockAmount}`}</p> : ""}

  <span data-toggle="tooltip" data-placement="top" title={product?.stockAvailability === "instock" ? "In stock, estimate delivery time: 2 - 6 days" : product?.stockAvailability === "out" ? "Out of stock" : " In stock at manufacturer, estimate delivery time: 18 days" }  style={{backgroundColor: product?.stockAvailability === "instock" ? "lightgreen" : product?.stockAvailability === "out" ? "red" : "orange" , width:"20px" , height:"20px"}} className="rounded-5 pointer ">
  
  </span>
</div>
          }
          
        </div>
        {!editOrDelete ?  (
          <div
            className="d-flex justify-content-between mt-2 w-100 "
            style={{ fontSize: "12px" }}
          >
            {!userEmail?.includes("microsoftsupplier185") && (
              <>
                <Link
                  to={`/shop/${product.name.replace(/ /g, "-")}`}
                  className="add-to-cart-button"
                >
                  {t("Add To Cart")}
                </Link>
                <Link
                  to={`/shop/${product.name.replace(/ /g, "-")}`}
                  className="dropship-button"
                >
                  {t("Buy")} now
                </Link>
              </>
            )}
          </div>
        ): ""}
      </div>
    ) : null;
  }
);

export default ProductCard;
