import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ProductCard from "./ProductCard";
import { deleteDoc, doc } from "firebase/firestore";
import toast from "react-hot-toast";
import { db } from "../../firebase/config";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SortableItem = ({ product, adminpage, editOrDelete, admin }) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: product.id });
    const navigate = useNavigate();
    const { t } = useTranslation();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };
    const handleDelete = (product) => {
        const storage = getStorage();

        const imageRef = ref(storage, product.imageUrl);
        // Delete the file
        deleteObject(imageRef)
          .then(() => {
            // File deleted successfully
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
    
        try {
          deleteDoc(doc(db, "products", product.id));
          toast.success(`${product?.name} deleted successfully`, {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#713200",
            },
            iconTheme: {
              primary: "#713200",
              secondary: "#FFFAEE",
            },
          });
        } catch (error) {}
      };
    
      const handleEdit = (product) => {
        navigate(`/admin/Add-product/${product.id}`);
      };
    return (
        <div className="d-flex flex-column product-cont">
        <ProductCard
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            product={product}
            editOrDelete={editOrDelete}
            admin={admin}
            adminpage={adminpage}
            {...attributes}
            {...listeners}
            />
              <div style={{height:"fit-content"}} className="edit-delete-buttons d-flex">
                  <button
                    type="button"
                    className="edit-button"
                    onClick={() => handleEdit(product)}
                  >
                    {t("Edit")}
                  </button>
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => handleDelete(product)}
                  >
                    {t("Delete")}
                  </button>
                </div>
            </div>
    );
};

export default SortableItem;
