import React, { useCallback, useEffect, useState } from "react";
import useFetchHook from "../../../hooks/useFetchHook";
import "./allProducts.css";
import { useDispatch } from "react-redux";
import { GET_PRODUCTS } from "../../../redux/slice/productsSlice";
import ProductCard from "../../../components/productCard/ProductCard";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "../../../components/productCard/SortableItem";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";

const AllProducts = () => {
  const data = useFetchHook("products" , "order" , "asc");
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  useEffect(() => {
    dispatch(GET_PRODUCTS(data));
    setItems(data);
  }, [data, dispatch]);

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(async (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active?.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);

      setItems((items) => {
        const newItems = arrayMove(items, oldIndex, newIndex);

        // Update order in Firestore
        newItems.forEach(async (item, index) => {
          try {
            const docRef = doc(db, "products", item.id);
            await updateDoc(docRef, { order: index });
          } catch (error) {
            console.error("Error updating document: ", error);
          }
        });

        return newItems;
      });
    }

    setActiveId(null);
  }, [items]);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);
 
  return (
    <div className="all-products">
      <div className="">
        {items && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              <div  className="all-products-container">
                {items?.map((product) => (
                  <SortableItem product={product} key={product.id} editOrDelete admin={true} adminpage />
                
                ))}
              </div>
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
              {activeId ? <ProductCard id={activeId} isDragging /> : null}
            </DragOverlay>
          </DndContext>
        )}
      </div>
    </div>
  );
};

export default AllProducts;
