import React, { useEffect, useState } from "react";
import "./addProducts.css";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Storage, db } from "../../../firebase/config";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProducts } from "../../../redux/slice/productsSlice";
import { v4 as uuidv4 } from "uuid";
import useFetchHook from "../../../hooks/useFetchHook";
import { AiOutlineCloseSquare } from "react-icons/ai";

const AddProducts = () => {
  const data = useFetchHook("products", "order", "asc");
  const products = useSelector(selectProducts);
  const [AddorEdit, setAddorEdit] = useState(true);
  const navigate = useNavigate();
  const initialValues = {
    name: "",

    type: "1",
stockAmount: null,
stockAvailability: null,
    price: "",
    "price(B2B)": "",
    "Vat(B2B)": "",
    vat: "",
    languages: [],
    imageUrl: "",
    PN: "",
  };
  const { id } = useParams();

  const handleEditProduct = () => {
    const findProduct = products.find((product) => product.id === id);
    if (findProduct) {
      return findProduct;
    } else {
      return initialValues;
    }
  };
  // useEffect(() => {
  //   setAddProduct(handleEditProduct());
  // }, [id]);
  useEffect(() => {
    if (id !== "new-product") {
      setAddorEdit(false);
    } else {
      setAddorEdit(true);
    }
  }, [id]);

  const [addProduct, setAddProduct] = useState(handleEditProduct());
  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddProduct({ ...addProduct, [name]: value });
  };

  //edit in firebase store
  const handleEditFirestore = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "products", id);
      const priceWVat = addProduct.vat
        ? addProduct.price * (1 + addProduct.vat / 100)
        : addProduct.price;
      // Update the document with new data
      const b2bpriceWVat = addProduct["Vat(B2B)"]
        ? addProduct["price(B2B)"] * (1 + addProduct["Vat(B2B)"] / 100)
        : addProduct["price(B2B)"];

      await updateDoc(docRef, {
        ...addProduct,
        priceWVat: priceWVat.toFixed(2),
        b2bpriceWVat: b2bpriceWVat.toFixed(2),
      });

      toast.success(`Product ${addProduct.name} Updated Successfully`);

      navigate("/admin/all-products");
    } catch (error) {
      toast.error(`"Error updating document:" ${addProduct.name} `);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const priceWVat = addProduct.vat
      ? addProduct.price * (1 + addProduct.vat / 100)
      : addProduct.price;

    const b2bpriceWVat = addProduct["Vat(B2B)"]
      ? addProduct["price(B2B)"] * (1 + addProduct["Vat(B2B)"] / 100)
      : addProduct["price(B2B)"];

    try {
      addDoc(collection(db, "products"), {
        ...addProduct,
        order: data?.length,
        priceWVat: priceWVat.toFixed(2),
        b2bpriceWVat: b2bpriceWVat.toFixed(2),
        createdAt: Date.now(),
      });
      setAddProduct(initialValues);
      toast.success(`Product ${addProduct.name} Added Successfully`, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#713200",
        },
        iconTheme: {
          primary: "#713200",
          secondary: "#FFFAEE",
        },
      });
      navigate("/admin/all-products");
    } catch (error) {}
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (addProduct.imageUrl) {
      const imageRef = ref(Storage, addProduct.imageUrl);
      // Delete the file
      deleteObject(imageRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
    try {
      const storageRef = ref(Storage, `products/${Date.now()}${file.name}`);

      // Upload the file and metadata
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          toast.error(error.message);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setAddProduct({ ...addProduct, imageUrl: downloadURL });
          });
        }
      );
    } catch (err) {}
  };

  const handleAddingLang = () => {
    setAddProduct((prevState) => ({
      ...prevState,
      languages: [...prevState.languages, { lang: "", PN: "", id: uuidv4() }],
    }));
  };
  const handleDeletingLang = (id) => {
    const updateLangs = addProduct.languages?.filter(obj => obj.id !== id)
    setAddProduct((prevState) => ({
      ...prevState,
      languages: updateLangs,
    }));
  };

  const handleLangChange = (e, item, id) => {
    const copyLanguages = [...addProduct.languages];
    const updateLang = copyLanguages.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          [item]: e.target.value,
        };
      } else {
        return obj;
      }
    });
    setAddProduct((prevState) => ({
      ...prevState,
      languages: updateLang,
    }));
  };
const handleStockAv = (e) => {
setAddProduct({...addProduct , stockAvailability: e.target.value})
}
  return (
    <div className="add-products w-100">
      <div className="add-products-container">
        <h1 className="add-product-header">
          {AddorEdit ? "Add Product" : "Edit Product"}
        </h1>
        <form
          className="add-product-form w-100"
          onSubmit={AddorEdit ? handleSubmit : handleEditFirestore}
        >
          <div>
            <label>Product Name:</label>
            <input
              onChange={(e) => handleChange(e)}
              value={addProduct.name}
              required
              type="text"
              name="name"
              placeholder="Micosoft windows 11"
            />
          </div>

          <div className="mt-5 d-flex align-items-center justify-content-center gap-5  ">
            <div className="w-100 w-md-50 w-lg-50">
              <label>Type:</label>
              <select
                onChange={(e) => handleChange(e)}
                required
                name="type"
                value={addProduct.type}
              >
                <option disabled value="1">
                  Select Type{" "}
                </option>
                <option value="physical software">PHYSICAL SOFTWARE</option>
                <option value="digital software">DIGITAL SOFTWARE</option>
              </select>
            </div>
            <div className="w-100 w-md-50 w-lg-50">
              <label>Price (Excl. VAT):</label>
              <label className="vat-label me-1" style={{ fontSize: "12px" }}>
                VAT % :
              </label>
              <input
                className="vat px-1"
                onChange={(e) => handleChange(e)}
                value={addProduct.vat}
                required
                type="number"
                name="vat"
              />
              <input
                onChange={(e) => handleChange(e)}
                value={addProduct.price}
                required
                type="number"
                name="price"
                placeholder="99.95"
              />
              <label>price(B2B):</label>
              <label className="vat-label me-1" style={{ fontSize: "12px" }}>
                VAT % :
              </label>
              <input
                className="vat px-1"
                onChange={(e) => handleChange(e)}
                value={addProduct["Vat(B2B)"]}
                required
                type="number"
                name="Vat(B2B)"
              />
              <input
                onChange={(e) => handleChange(e)}
                value={addProduct["price(B2B)"]}
                required
                type="number"
                name="price(B2B)"
                inputMode="decimal"
                placeholder="79"
              />
            </div>
          </div>

          <button
            className="my-5 fit-content rounded-2"
            onClick={handleAddingLang}
            style={{ width: "fit-content" }}
            type="button"
          >
            + Add languages
          </button>
          {addProduct?.languages?.length ? (
            addProduct?.languages.map((lang, i) => (
              <div
              
                key={i}
                className="w-100 border p-3 w-md-50 w-lg-50 d-flex gap-2 my-5"
              >
                <div className="w-50">
                  <label className="">Language:</label>
                  <input
                    className=""
                    onChange={(e) => handleLangChange(e, "lang", lang.id)}
                    value={lang.lang}
                    required
                    type="text"
                    name="lang"
                    placeholder="French"
                  />
                </div>
                <div className="w-50">
                  <label className="">PN:</label>
                  <input
                    className=""
                    onChange={(e) => handleLangChange(e, "PN", lang.id)}
                    value={lang.PN}
                    required
                    type="text"
                    name="PN"
                    placeholder="xxxx-yyyy or xxxxx-yyyyy"
                  />
                </div>
                <AiOutlineCloseSquare className="pointer" onClick={() => handleDeletingLang(lang.id)}/>

              </div>
            ))
          ) : (
            <div className="w-100 w-md-50 w-lg-50">
              <label className="">PN:</label>
              <input
                className=""
                onChange={(e) => handleChange(e)}
                value={addProduct["PN"]}
                required
                type="text"
                name="PN"
                placeholder="xxxx-yyyy or xxxxx-yyyyy"
              />
            </div>
          )}
          <div className="d-flex w-100 items-center gap-3 justify-between">

 <div className=" w-50 my-3">
              <label className="">Stock Amount:</label>
              <input
                className=""
                onChange={(e) => handleChange(e)}
                value={addProduct["stockAmount"]}
                
                type="number"
                name="stockAmount"
                placeholder=""
              />
            </div>

            <div className="w-50 mw-100 my-3">
              <h1 className="fs-6">Stock Availablity:</h1>
<label class="stockcontainer">In stock
  <input onChange={handleStockAv} className="w-100 d-block" type="radio" checked={addProduct.stockAvailability === "instock"} value="instock" name="radio"/>
  <span class="checkmark instock"></span>
</label>
<label class="stockcontainer">In stock at manufacturer
  <input onChange={handleStockAv} className="w-100" type="radio"checked={addProduct.stockAvailability === "manufacturer"} value="manufacturer" name="radio"/>
  <span class="checkmark manufacturer"></span>
</label>
<label class="stockcontainer">Out of stock
  <input onChange={handleStockAv} className="w-100" type="radio" checked={addProduct.stockAvailability === "out"} value="out" name="radio"/>
  <span class="checkmark out"></span>
</label>
            </div>
          </div>

          <div className="mt-5   ">
            <label>Upload Image:</label>
            <div className="position-relative">
              <p
                style={{ width: `${progress}%`, maxWidth: "99%" }}
                className="progress"
              ></p>
              <input
                onChange={(e) => handleImageUpload(e)}
                type="file"
                accept="image/*"
                capture="environment"
              />
            </div>
            <input
              value={addProduct.imageUrl}
              type="text"
              disabled
              placeholder="image Url"
              required
            />
          </div>
          <button
            disabled={addProduct.imageUrl ? false : true}
            type="submit"
            className="add-product-button mt-5 w-100"
          >
            {AddorEdit ? "Add Product" : "Edit Product"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProducts;
