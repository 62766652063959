import { useState, useEffect } from "react";
import { collection,query ,onSnapshot, orderBy} from 'firebase/firestore';
import { db } from '../firebase/config';

const useFetchHook = (collectionName  , orderUsing = "createdAt" , dir = 'asc' ) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const q = query(collection(db, collectionName),orderBy(orderUsing, dir));
   onSnapshot(q, (querySnapshot) => {
      const products = [];
      querySnapshot.forEach((doc) => {
        products.push({...doc.data() , id:doc.id});
      });
      setData(products)
    });
  }, [collectionName, orderUsing]);

  return data;
};

export default useFetchHook;
