import React, { useEffect } from "react";
import Hero from "../../components/hero/Hero";
import "./home.css";
import Plans from "../../components/plans/Plans";
import { useDispatch } from "react-redux";
import { CLOSE_LOADING, OPEN_LOADING } from "../../redux/slice/loadingSlice";
import video from "../../assets/videos/supp.mp4";
import { Link } from "react-router-dom";
import {
  animated,
  useSpring,
  useSpringRef,
  useScroll,
} from "@react-spring/web";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const Home = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  useEffect(() => {
    dispatch(OPEN_LOADING());
    // Simulate a delay to showcase the loader
    const delay = setTimeout(() => {
      dispatch(CLOSE_LOADING());
    }, 3000); // Set the desired delay time

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(delay);
  }, [dispatch]);
  return (
    <div className="home">
      <Hero />
      <motion.div
      initial={{ scale: .9 }}
      whileInView={{ scale: 1.0 }} 
      
      className="video-section">
        <motion.video width="100%" loop autoPlay muted>
          <source src={video} type="video/mp4" />
        </motion.video>
        <div className="video-text">
          <div>
            <h1>{t('Check our shop for more of our products')}</h1>
            <Link to="/shop">{t('Check Other Products')}</Link>
          </div>
        </div>
      </motion.div>
      <div className="why-us-section px-5">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="align-self-start mb-5"
        >
          {t('Transparent')}
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          {t('Reliable')}
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2.5 }}
          className="align-self-end mt-5"
        >
          {t('Connecting')}
        </motion.p>
      </div>
      <Plans />

      
      <motion.div id="contact"
       whileInView={{ opacity:1}}
       initial={{ opacity:0 }}
      
      className="talk-to-us">
        <div className="container">
          <p>{t('Talk To Us')}</p>
          <motion.a 
         
          
          
          href="mailto:info@microsoftsupplier.com">
            info@microsoftsupplier.com
          </motion.a>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
