import React from 'react'
import useFetchHook from '../../../hooks/useFetchHook';
import './home.css'
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useEffect } from 'react';
const Home = () => {
  const data = useFetchHook("category");
useEffect(() => {
 const fetchh = async() => {
try {
  const stripe = require('stripe')('sk_test_51Na06XDvCe1ScO91dDsIfQdnq9oEMkZ60igWraJFBVS7hIglJPfixy5LtasIT2lDzold4pQO7TYWjVUErPmFMvFC007Keokkxl');

const paymentIntents = await stripe.paymentIntents.list({
  limit: 3,
});

console.log(paymentIntents.data[0].metadata);
} catch (error) {
  
}


 }
 fetchh()
}, [])



  return (
    <div className='admin-home py-4'>
        <div className='container'>
          <table className='styled-table'>
  <thead>
    <tr>
      <th>Email</th>
      <th>category</th>
    </tr>
  </thead>
  <tbody>
{data?.map( (item , index) =>{


  return(
    <tr key={index}>
        <td>{item.useremail}</td>
        <td>{item.cat}</td>
      </tr>
  )})}    

  
  </tbody>
</table>
  





        
        
        </div>
        
        </div>
  )
}

export default Home